import { Controller } from "@hotwired/stimulus"
import ApexCharts from 'apexcharts'

export default class extends Controller {
  static values = {
    data: Array
  }

  connect() {
    const options = {
      colors: ["#8B5CF6"],
      series: [
        {
          name: "Sessões",
          data: this.dataValue,
        },
      ],
      chart: {
        type: "bar",
        height: 320,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 8,
        },
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
      stroke: {
        show: false,
      },
      grid: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        floating: false,
        labels: {
          show: true,
          style: {
            cssClass: 'text-xs font-normal fill-neutral-500'
          }
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        stepSize: 1,
      }
    }

    this.chart = new ApexCharts(this.element, options);
    this.chart.render();
  }

  disconnect() {
    this.chart.destroy();
  }
}
